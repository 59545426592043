<template>
  <div>
    <div
      v-if="isLabels"
      class="ProgressLabels" :class="{ isCompact }"
      key="labels">
      <div class="ProgressLabels__line"></div>

      <div
        v-for="(step, stepIdx) in steps"
        class="ProgressLabels__step"
        :class="{ isActive: isStepActive(step)}"
        :key="step.id">
        <div class="step__number">{{ stepIdx + 1 }}</div>
        <div class="step__label">{{ step.label }}</div>
      </div>
    </div>

    <div
      v-else
      class="ProgressDefault"
      key="default">
      <span
        class="ProgressDefault__line">
      </span>

      <div
        class="ProgressDefault__label">
        {{
          $t('shared.progressLabel', { currentStep: activeStepIndex + 1, totalSteps: steps.length })
        }}
      </div>

      <span
        class="ProgressDefault__line">
      </span>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ProgressStepper',

  props: {
    activeStepId: {
      type: String,
      default: null,
    },

    isCompact: {
      type: Boolean,
      default: false,
    },

    isLabels: {
      type: Boolean,
      default: false,
    },

    steps: {
      type: Array, // { id: string, label: string }[]
      required: true,
    },
  },

  computed: {
    activeStepIndex() {
      return this.steps.findIndex((step) => step.id === this.activeStepId) || 0;
    },
  },

  methods: {
    isStepActive(step) {
      const stepIndex = this.steps.findIndex((s) => s.id === step.id) || 0;
      return stepIndex <= this.activeStepIndex;
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

// Default
.ProgressDefault {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: $mute;
  font-size: 16px;
  font-weight: $font-weight-bold;
  padding-top: 16px;
}

.ProgressDefault__label {
  padding: 12px;
}

.ProgressDefault__line {
  flex: 1 1 50%;
  height: 1px;
  background: $border-color;
}


// Labels
.ProgressLabels {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 32px;
  position: relative;
  white-space: nowrap;
}

.ProgressLabels__line {
  background: #BAC8D6;
  bottom: 0;
  height: 1px;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  width: 100%;
}

.ProgressLabels__step {
  align-items: center;
  background: #FFFFFF;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  padding-right: 8px;
  z-index: 1;
  & + .ProgressLabels__step {
    padding-left: 8px;
  }
  &:last-child {
    padding-right: 0px !important;
  }
}
.ProgressLabels__step.isActive {
  .step__number {
    background: $light-blue;
    color: #111111;
  }
  .step__label {
    color: #111111;
  }
}

.step__number,
.step__label {
  font-size: 16px;
  font-weight: 400;
}

.step__number {
  background: #888888;
  border-radius: 50%;
  color: #FFFFFF;
  flex: 0 0 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  width: 32px;
}

.step__label {
  color: #888888;
  flex: 0 0 auto;
  padding-left: 8px;
}

// Labels.isCompact
.isCompact {
  .ProgressLabels__line {
    display: none;
  }

  .ProgressLabels__step {
    display: block !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }

  .step__number,
  .step__label {
    font-size: 12px;
    font-weight: 600;
  }

  .step__number {
    height: 24px;
    line-height: 24px;
    margin: 0 auto;
    width: 24px;
  }

  .step__label {
    margin-top: 8px;
    padding-left: 0px !important;
  }
}
</style>
